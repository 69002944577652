<template>
  <div>
    <b-row>
      <!-- Section 1 -->
      <b-col
        xl="3"
        cols="12"
        class="px-custom-r"
      >
        <b-row>
          <b-col>
            <!-- Show patient info -->
            <patientDetail
              class="container"
              :hn="hn"
            />
            <!-- Show patient info -->
          </b-col>
        </b-row>
      </b-col>
      <!-- Section 1 -->
      <!-- Section 2 -->
      <b-col
        xl="9"
        cols="12"
        class="px-custom-l"
      >
        <b-overlay
          rounded="sm"
        >
          <b-card class="container px-0 card-padding">
            <h5 class="text-primary">
              <feather-icon
                icon="FolderIcon"
                class="mr-75"
              />บันทึกเวชระเบียน</h5>
            <hr>
            <b-row>
              <b-col
                xl="3"
                cols="3"
                class="picHeight"
              >
                <!-- Show patient timeline -->
                <b-row>
                  <b-col
                    class="pr-0"
                  >
                    <b-form-group>
                      <v-select
                        v-model="selectedType"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        multiple
                        placeholder="กรุณาเลือกประเภทเอกสาร"
                        :options="typeFilter"
                      />
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-overlay
                  variant="transparent"
                  class="m-auto pt-1"
                  :show="loadTimeline"
                  rounded="sm"
                >
                  <app-timeline>
                    <app-timeline-item
                      v-for="(item, finalDataIndex) in hieDetail"
                      :key="finalDataIndex"
                      class="container"
                      variant="success"
                    >
                      <div
                        class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0"
                      >
                        <h5>{{ item.date }}</h5>
                      </div>
                      <!-- Show OPD/IPD/Document -->
                      <!-- OPD/MR -->
                      <div
                        v-for="(mrData, mrIndex) in item.medicalDocument.mr"
                        :key="item.date + mrIndex + 'mr'"
                        class="justify-content-between"
                      >
                        <b-form-checkbox
                          v-if="mrData.location !== ''"
                          v-model="mrSelected"
                          :value="{Location: mrData.location, NameImage: mrData.imageBase64, Date: item.date, Type: 'MR'}"
                          plain
                        >
                          <label
                            class="pointer"
                            @click="getMr_Image(mrData, item.date)"
                          >
                            <b-badge
                              pill
                              variant="light-primary"
                            >
                              MR
                            </b-badge>
                            {{ mrData.location }}
                          </label>
                        </b-form-checkbox>
                      </div>
                      <!-- OPD/EMR -->
                      <div
                        v-for="(emrData, emrIndex) in item.medicalDocument.emr"
                        :key="index + emrIndex + 'emr'"
                        class="justify-content-between"
                      >
                        <b-form-checkbox
                          v-if="emrData.location !== ''"
                          v-model="emrSelected"
                          :value="{NameImage: emrData.txn, Date: item.date, Location: emrData.location, Type: 'EMR'}"
                          plain
                        >
                          <label
                            v-if="emrData.location !== ''"
                            class="pointer"
                            @click="getEmr(emrData, item.date)"
                          >
                            <b-badge
                              pill
                              href="#"
                              variant="light-success"
                            >
                              EMR
                            </b-badge>
                            {{ emrData.location }}
                          </label>
                        </b-form-checkbox>
                      </div>
                      <!-- IPD -->
                      <div v-if="item.medicalDocument.ipd.length > 0">
                        <b-form-checkbox
                          v-model="ipdSelected"
                          :value="{date: item.date}"
                          disabled
                          plain
                        >
                          <label
                            class="pointer"
                            @click="getIpd(item.date)"
                          >
                            <b-badge
                              pill
                              variant="light-success"
                            >
                              IPD
                            </b-badge>
                          </label>
                        </b-form-checkbox>
                      </div>
                      <!-- Other -->
                      <div
                        v-for="(otherData, otherIndex) in item.medicalDocument.docName"
                        :key="index + otherIndex + 'other'"
                        class="justify-content-between"
                      >
                        <b-form-checkbox
                          v-if="otherData.location !== ''"
                          v-model="otherSelected"
                          :value="{date: item.date, Index: otherIndex}"
                          plain
                          disabled
                        >
                          <label
                            class="pointer"
                            @click="getOtherImage(otherData, item.date, otherIndex)"
                          >
                            <b-badge
                              pill
                              variant="light-success"
                            >
                              Other
                            </b-badge>
                            {{ otherData }}
                          </label>
                        </b-form-checkbox>
                      </div>
                      <!-- Show OPD/IPD/Document -->
                    </app-timeline-item>
                  </app-timeline>
                </b-overlay>
                <!-- Show patient timeline -->
              </b-col>
              <b-col
                id="ipdScroll"
                xl="9"
                cols="9"
                class="picHeight2"
              >
                <!-- Show medical record -->
                <viewer-preview
                  v-if="viewerPreviewKey !== ''"
                  :key="viewerPreviewKey"
                  style="border-style: soild; border-color: gray;"
                  :check-all-status="checkAllStatus"
                  :off-set="offSet"
                  :type="type"
                  :date="date"
                  :pic="pic"
                  :pic-group="picGroup"
                  :hn="hn"
                  :txn="txn"
                  :group="group"
                  :index="index"
                  class="container"
                  @sendToCheckBox="keepCheckBox"
                  @spliceOtherData="deletedOther"
                  @spliceIpdData="deleteIpd"
                />
              <!-- <general /> -->
              <!-- Show medical record -->
              </b-col>
            </b-row>
            <b-row class="mt-1">
              <b-col class="d-flex align-items-center justify-content-end">
                <!-- <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="success"
                  @click="confirm"
                  @click="sendData()"
                >
                  เพิ่มรายการ
                </b-button> -->
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="success"
                  @click="confirmData()"
                >
                  เพิ่มรายการ
                </b-button>
              </b-col>
            </b-row>
          </b-card>
        </b-overlay>
      </b-col>
      <!-- Section 2 -->
    </b-row>
  </div>
</template>

<script>
import Ripple from 'vue-ripple-directive'
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'
// import general from '@/components/record-viewer/general.vue'
import {
  BButton, BRow, BCol, BFormGroup,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import patientDetail from '../../patientInfo/patientDetail.vue'
import viewerPreview from '../viewerPreview/viewerPreview.vue'
import store from '../../../../store'

export default {
  directives: { Ripple },
  components: {
    // eslint-disable-next-line vue/no-unused-components
    patientDetail,
    viewerPreview,
    AppTimeline,
    AppTimelineItem,
    BButton,
    BRow,
    BCol,
    BFormGroup,
    vSelect,
    // general,
  },
  data() {
    return {
      offSet: '',
      windowTop: 0,
      show: false,
      pic: '',
      picGroup: [],
      hn: '',
      rid: '',
      group: '',
      type: '',
      date: '',
      data: [],
      viewerPreviewKey: '',
      txn: '',
      index: '',
      otherSelected: [],
      mrSelected: [],
      ipdSelected: [],
      emrSelected: [],
      timelineTemp: [],
      otherItems: [
        {
          otherDate: '',
          otherName: '',
          otherData: [],
        },
      ],
      checkAllStatus: false,
      disableCheckAllStatus: false,
      hieDetail: '',
      typeFilter: ['EMR', 'MR', 'IPD', 'Other'],
      selectedType: ['EMR', 'MR', 'IPD', 'Other'],
      loadTimeline: false,
    }
  },
  watch: {
    async selectedType() {
      this.loadTimeline = true
      this.hieDetail = ''
      this.timelineTemp = []
      await this.getTimeLine()
      await this.filterDocumnet()
      this.loadTimeline = false
    },
  },
  async created() {
    this.loadTimeline = true
    this.hn = this.$route.params.hn
    this.rid = this.$route.params.rid
    await this.getTimeLine()
    this.loadTimeline = false
  },
  methods: {
    async sendData() {
      const packData = []
      const other = store.getters['hieStateImage/setAllOther']
      const ipd = store.getters['hieStateImage/setAllIpd']
      const mr = this.mrSelected
      const emr = this.emrSelected
      await this.getTimeLine()
      if (mr) {
        mr.forEach(element => {
          packData.push({
            rid: this.rid,
            nameImage: element.NameImage,
            date: element.Date,
            location: element.Location,
            type: element.Type,
          })
        })
      }
      if (other) {
        other.forEach(element => {
          element.forEach(data => {
            packData.push({
              rid: this.rid,
              nameImage: data.nameImage,
              date: data.date,
              location: data.group,
              type: data.type,
            })
          })
        })
      }
      if (ipd) {
        ipd.forEach(element => {
          element.forEach(data => {
            this.timelineTemp.every(elements => {
              if (data.date === elements.date) {
                // get ipd nameImage
                packData.push({
                  rid: this.rid,
                  nameImage: elements.medicalDocument.ipd[data.index].imageBase64,
                  date: data.date,
                  location: data.date,
                  type: data.type,
                })
                return false
              }
              return true
            })
          })
        })
      }
      if (emr) {
        emr.forEach(element => {
          packData.push({
            rid: this.rid,
            nameImage: element.NameImage,
            date: element.Date,
            location: element.Location,
            type: element.Type,
          })
        })
      }
      const Doc = []
      Doc.push({ Doc: packData })
      this.$http({
        url: 'api/hospital-information-exchange/addDocument',
        method: 'POST',
        data: {
          Docs: Doc[0].Doc,
        },
      })
    },
    async confirmData() {
      await this.$swal({
        title: 'คุณแน่ใจหรือไม่?',
        text: 'เอกสารที่เลือกจะไม่สามารถลบออกจากเอกสารได้',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'OK',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.backToRequest()
        } else if (result.dismiss === 'cancel') {
          this.$swal({
            title: 'การโอนไม่สำเร็จ',
            text: 'เอกสารที่เลือกยังไม่ถูกโอนไปยังผู้ส่งคำขอ',
            icon: 'error',
            customClass: {
              confirmButton: 'btn btn-success',
            },
          })
        }
      })
    },
    async backToRequest() {
      await this.sendData()
      await this.$swal({
        icon: 'success',
        title: 'การโอนเอกสารสำเร็จ',
        text: 'สามารถโอนเอกสารเพิ่มภายหลังได้',
        customClass: {
          confirmButton: 'btn btn-success',
        },
      })
      await this.$router.push({ name: 'RequestHistory' })
    },
    getMr_Image(mrData, date) {
      this.pic = mrData.imageBase64
      this.type = 'MR'
      this.group = mrData.location
      this.date = date
      this.viewerPreviewKey = this.pic
      this.txn = ''
    },
    getIpd(getDate) {
      this.offSet = 0
      this.date = getDate
      this.type = 'IPD'
      this.viewerPreviewKey = this.date
      this.txn = ''
    },
    async getTimeLine() {
      await this.$http({
        url: '/api/Profile/HIEDetail',
        method: 'POST',
        data: {
          HN: this.hn,
        },
      }).then(({ data }) => {
        let date1 = ''
        let theOtherIndex = 0
        let date2 = ''
        data.forEach((element, index) => {
          const myarray = []
          if (element.medicalDocument.other.length !== 0) {
            date2 = element.date
            for (
              let indexs = 0;
              indexs < element.medicalDocument.other.length;
              indexs += 1
            ) {
              if (
                myarray.includes(
                  element.medicalDocument.other[indexs].location,
                ) !== true
              ) {
                if (date1 === date2) {
                  theOtherIndex += 1
                } else {
                  date1 = date2
                  theOtherIndex = 0
                }
                myarray.push(element.medicalDocument.other[indexs].location)
                this.otherItems.push({
                  otherIndex: theOtherIndex,
                  otherDate: element.date,
                  otherName: element.medicalDocument.other[indexs].location,
                  otherData: [
                    {
                      imageBase64:
                        element.medicalDocument.other[indexs].imageBase64,
                    },
                  ],
                })
              } else {
                const otherItemsIndex = this.otherItems.findIndex(
                  x => x.otherDate === element.date
                    && x.otherName
                      === element.medicalDocument.other[indexs].location,
                )
                this.otherItems[otherItemsIndex].otherData.push({
                  imageBase64:
                    element.medicalDocument.other[indexs].imageBase64,
                })
              }
            }
            // eslint-disable-next-line no-param-reassign
            data[index].medicalDocument.docName = myarray
          }
        })
        for (let index = 0; index < data.length; index += 1) {
          if (
            data[index].medicalDocument.emr.length === 0
            && data[index].medicalDocument.mr.length === 0
            && data[index].medicalDocument.ipd.length === 0
            && data[index].medicalDocument.other.length === 0
          ) {
            // eslint-disable-next-line no-continue
            continue
          } else {
            this.timelineTemp.push(data[index])
          }
        }
        this.timelineTemp.sort((a, b) => new Date(b.date) - new Date(a.date))
        this.hieDetail = this.timelineTemp
      })
      // await store.commit('hieDetail/getHieTimeLine', this.timelineTemp)
    },
    getOtherImage(otherType, date, otherIndex) {
      const index = this.otherItems.findIndex(
        x => x.otherDate === date && x.otherName === otherType,
      )
      this.group = this.otherItems[index].otherName
      this.date = this.otherItems[index].otherDate
      this.picGroup = this.otherItems[index].otherData
      this.type = 'Other'
      this.viewerPreviewKey = this.date + this.group
      this.index = otherIndex
      this.txn = ''
    },
    checkAllStatusFlag() {
      if (this.disableCheckAllStatus === false) {
        this.checkAllStatus = true
      }
    },
    getEmr(emrData, date) {
      this.txn = emrData.txn
      this.type = 'EMR'
      this.group = emrData.location
      this.date = date
      this.viewerPreviewKey = this.txn
    },
    keepCheckBox(getIndex, getDate, getType) {
      this.index = getIndex
      this.date = getDate
      this.type = getType
      if (this.type === 'Other') {
        this.otherSelected.push({
          date: this.date, Index: this.index,
        })
      } else {
        this.ipdSelected.push({
          date: this.date,
        })
      }
    },
    deletedOther(getIndex, getDate) {
      const otherSelectedIndex = this.otherSelected.findIndex(element => element.Index === getIndex && element.date === getDate)
      this.otherSelected.splice(otherSelectedIndex, 1)
    },
    deleteIpd(getIndex, getDate) {
      const ipdSelectedIndex = this.ipdSelected.findIndex(element => element.Index === getIndex && element.date === getDate)
      this.ipdSelected.splice(ipdSelectedIndex, 1)
    },
    async filterDocumnet() {
      await this.hieDetail.forEach(element => {
        if (!this.selectedType.includes('MR')) {
          // eslint-disable-next-line no-param-reassign
          element.medicalDocument.mr = []
        }
        if (!this.selectedType.includes('EMR')) {
          // eslint-disable-next-line no-param-reassign
          element.medicalDocument.emr = []
        }
        if (!this.selectedType.includes('IPD')) {
          // eslint-disable-next-line no-param-reassign
          element.medicalDocument.ipd = []
        }
        if (!this.selectedType.includes('Other')) {
          // eslint-disable-next-line no-param-reassign
          element.medicalDocument.other = []
          // eslint-disable-next-line no-param-reassign
          element.medicalDocument.docName = []
        }
      })
      const hieTemp = []
      await this.hieDetail.forEach(element => {
        if (element.medicalDocument.mr.length !== 0 || element.medicalDocument.emr.length !== 0 || element.medicalDocument.ipd.length !== 0 || element.medicalDocument.other.length !== 0) {
          hieTemp.push(element)
        }
      })
      this.hieDetail = hieTemp
    },
  },
}
</script>

<style>
.pointer {
  cursor: pointer;
}
.picHeight{
  height: 600px;
  /* overflow: auto; */
   overflow-y: scroll;
}
.picHeight::-webkit-scrollbar {
    display: none;
}
.example::-webkit-scrollbar {
    display: none;
}
/* Hide scrollbar for IE, Edge and Firefox */
.example {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}
.picHeight2 {
  height: 600px;
  overflow: auto;
}
.card-padding .card-body{
  padding: 15px;
}
.px-custom-l{
  padding-left: 5px;
}
.px-custom-r{
  padding-right: 5px;
}
@media screen and (max-width: 1200px) {
  .px-custom-l {
    padding-left: 14px;
    padding-right: 14px;
  }
  .px-custom-r {
    padding-left: 14px;
    padding-right: 14px;
  }
}
</style>

<style lang='scss' scoped>
</style>
