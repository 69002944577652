<template>
  <b-card>
    <h5 class="text-primary">
      <feather-icon
        icon="UserIcon"
        class="mr-75"
      />ข้อมูลผู้รับบริการ</h5>
    <hr>
    <!-- <b-row>
      <b-col
        cols="12"
        xl="12"
      >
        <b-form-group>
          <b-input-group class="input-group-merge">
            <b-input-group-prepend is-text>
              <feather-icon icon="SearchIcon" />
            </b-input-group-prepend>
            <b-form-input
              v-model="hn"
              type="number"
              placeholder="HN"
              @keyup.enter="getPatientData(hn)"
            />
          </b-input-group>
        </b-form-group>
      </b-col>
    </b-row> -->
    <!-- Show patient picture -->
    <b-row>
      <b-col
        xl="12"
        cols="12"
      >
        <b-avatar
          rounded="sm"
          :src="patient.image"
          size="90px"
        />
      </b-col>
    </b-row>
    <!-- Show patient picture -->
    <!-- Show patient information -->
    <b-row class="mt-1">
      <b-col>
        <b-row class="mt-xl-0 w-100">
          <b-col class="pb-50">
            <feather-icon
              icon="UserIcon"
              class="mr-75"
            />
            <span class="font-weight-bold">ชื่อ - สกุล</span>
          </b-col>
          <b-col
            cols="7"
            class="pb-50"
          >
            {{ patient.name }}
          </b-col>
        </b-row>
        <b-row class="mt-xl-0 w-100">
          <b-col class="pb-50">
            <feather-icon
              icon="AtSignIcon"
              class="mr-75"
            />
            <span class="font-weight-bold">เลขที่ รพ.</span>
          </b-col>
          <b-col
            cols="7"
            class="pb-50"
          >
            {{ patient.hn }}
          </b-col>
        </b-row>
        <b-row class="mt-xl-0 w-100">
          <b-col class="pb-50">
            <span class="font-weight-bold">วันเดือนปีเกิด</span>
          </b-col>
          <b-col
            cols="7"
            class="pb-50"
          >
            {{ patient.birth }} <label>{{ patient.age }}</label>
          </b-col>
        </b-row>
        <b-row class="mt-xl-0 w-100">
          <b-col class="pb-50">
            <feather-icon
              icon="FlagIcon"
              class="mr-75"
            />
            <span class="font-weight-bold">ที่อยู่</span>
          </b-col>
          <b-col
            cols="7"
            class="pb-50"
          >
            {{ patient.address }}
          </b-col>
        </b-row>
        <b-row class="mt-xl-0 w-100">
          <b-col class="pb-50">
            <span class="font-weight-bold">สิทธิ์การรักษา</span>
          </b-col>
          <b-col
            cols="7"
            class="pb-50"
          >
            {{ patient.payor }}
          </b-col>
        </b-row>
        <b-row class="mt-xl-0 w-100">
          <b-col class="pb-50">
            <feather-icon
              icon="AlertTriangleIcon"
              class="mr-75"
            />
            <span class="font-weight-bold">แพ้ยา</span>
          </b-col>
          <b-col
            cols="7"
            class="pb-50"
          >
            {{ patient.allergy.med }}
          </b-col>
        </b-row>
        <!-- <table class="mt-xl-0 w-100 "> -->
        <!-- <tr>
            <th class="pb-50">
              <feather-icon
                icon="UserIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">ชื่อ - สกุล</span>
            </th>
            <td class="pb-50">
              {{ patient.name }}
            </td>
          </tr> -->
        <!-- <tr>
            <th class="pb-50">
              <feather-icon
                icon="AtSignIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">เลขที่ รพ.</span>
            </th>
            <td class="pb-50 text-capitalize">
              {{ patient.hn }}
            </td>
          </tr> -->
        <!-- <tr>
            <th class="pb-50">
              <feather-icon
                icon="StarIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">วันเดือนปีเกิด</span>
            </th>
            <td class="pb-50 text-capitalize">
              {{ patient.birth }}
              <br>
              {{ patient.age }}
            </td>
          </tr> -->
        <!-- <tr>
            <th class="pb-50">
              <feather-icon
                icon="FlagIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">ที่อยู่</span>
            </th>
            <td class="pb-50">
              {{ patient.address }}
            </td>
          </tr> -->
        <!-- <tr>
            <th class="pb-50">
              <feather-icon
                icon="UserPlusIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">สิทธิ์การรักษา</span>
            </th>
            <td class="pb-50">
              {{ patient.payor }}
            </td>
          </tr> -->
        <!-- <tr>
            <th class="pb-50">
              <feather-icon
                icon="AlertTriangleIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">แพ้ยา</span>
            </th>
            <td class="pb-50">
              {{ patient.allergy.med }}
            </td>
          </tr> -->
        <!-- </table> -->
      </b-col>
    </b-row>
    <!-- Show patient information -->
  </b-card>
</template>

<script>
export default {
  props: {
    hn: {
      type: String,
      default: () => '',
    },
  },
  data() {
    return {
      patient: {
        hn: '',
        payor: '',
        plan: '',
        cid: '',
        image: '',
        name: '',
        birth: '',
        age: '',
        phone: '',
        address: '',
        message: {
          medical: '',
          finance: '',
          other: '',
        },
        allergy: {
          med: '',
          food: '',
          other: '',
        },
      },
    }
  },
  created() {
    this.getPatientData()
  },
  methods: {
    getPatientData() {
      this.patient = {
        hn: '',
        payor: '',
        plan: '',
        cid: '',
        image: '',
        name: '',
        birth: '',
        age: '',
        phone: '',
        address: '',
        message: {
          medical: '',
          finance: '',
          other: '',
        },
        allergy: {
          med: '',
          food: '',
          other: '',
        },
      }
      if (this.hn !== '') {
        this.hn = `000000${this.hn}`.substr(-7)
        this.$http({
          url: 'api/Patient/patientdetail',
          method: 'POST',
          data: {
            HN: this.hn,
          },
        }).then(({ data }) => {
          if (data.length === 1) {
            this.patient.hn = data[0].hn
            this.patient.payor = data[0].payorMain.insT_Desc
            this.patient.cid = data[0].idCard
            this.patient.name = `${data[0].title} ${data[0].fName} ${data[0].lName}`
            this.patient.birth = this.$helpers.getFullDate(data[0].dob.substr(0, 10))
            this.patient.age = `(${(data[0].age.substr(5)).replaceAll('(s)', '').replace('Year', 'ปี').replace('Month', 'เดือน')
              .replace('Day', 'วัน')}${')'}`
            this.patient.image = `data:image/jpeg;base64, ${data[0].image}`
            this.patient.phone = data[0].phone
            this.patient.address = data[0].miDetail
            this.patient.allergy.med = data[0].allergicDrugGroup
            // this.sendHN()
          } else {
            this.patient = {
              hn: '',
              image: '',
              name: '',
              birth: '',
              age: '',
              phone: '',
              address: '',
              message: {
                medical: '',
                finance: '',
                other: '',
              },
              appointments: [],
            }
          }
        })
      }
    },
    // sendHN() {
    //   this.$emit('sendHN', this.hn)
    // },
  },
}
</script>

<style scoped>
</style>
